/**
 * @file BrandsTable.tsx BrandsTable component
 * @author Harry Rhodes
 * @exports React.Component
 */
import { Typography} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import brandService, { BrandType } from "../../../services/brandService";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import useStyles, { sxProps } from "./styles";
import { useState } from "react";
import SearchFieldToolbar from "../../common/DataGrid/SearchFieldToolbar";
import { useMutation, useQueryClient } from "react-query";
import { invalidateBrandQueries } from "../../../utils/invalidateQueries";
import { useUserInfo } from "../../../hooks/useUserInfo";
import DataGridLogo from "../../common/DataGrid/DataGridLogo";

/**
 * Props
 * @typedef {data: BrandType[], partnerAlias: string} Props
 */
interface Props {
  data: BrandType[];
  partnerAlias: string;
  setSuccessMessage: (s: string) => void;
  setErrorMessage: (s: string) => void;
}

/**
 * Renders BrandsTable component
 * @param props component props @see Props
 * @returns {React.Component} BrandsTable component
 */
export default function BrandsTable(props: Props) {
  const navigate = useNavigate();
  const { role } = useUserInfo();
  const location = useLocation();
  const classes = useStyles();
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const queryClient = useQueryClient();
  const { mutateAsync: deleteBrand } = useMutation((ids: string[]) => Promise.all(ids.map(brandService.delete)), {
    onSuccess: (results) => {
      props.setSuccessMessage("Deleted " + results.length + " Brand(s)!");
    },
    onSettled: () => {
      invalidateBrandQueries(queryClient);
    }
  });

  /**
   * Navigates to relevant brand
   */
  const handleClick = (id: string) =>
    navigate(location.pathname + "/brands/" + id, {
      state: { partnerAlias: props.partnerAlias },
    });

  if (props.data.length === 0) return <Typography>No Brands</Typography>;

  const columns: GridColDef[] = [
    {
      field: 'logo',
      headerName: '',
      renderCell: (params) => <DataGridLogo logoSrc={params.value.brandLogo} />,
      width: 150,
      sortable: false
    },
    { field: 'name', headerName: 'Name', width: 450 },
    { field: 'alias', headerName: 'Alias', width: 250 },
    { field: 'paiContingent', headerName: 'PAI Contingent', width: 120, align: 'right' }
  ];

  const rows = props.data.map((brand) => ({
    id: brand.id,
    logo: { brandLogo: brand.logo },
    name: brand.name,
    alias: brand.alias,
    paiContingent: brand.pai_contingent
  }));

  const canDelete: boolean = role === "vodafone-admin" || role === "partner-manager";

  return (
    <DataGrid className={classes.datagrid}
      // Style for search toolbar
      sx={sxProps.datagridToolbar}
      disableColumnMenu
      disableRowSelectionOnClick
      autoHeight
      checkboxSelection={canDelete}
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' }],
        },
      }}
      pageSizeOptions={[10, 20, 50, 100]}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      onRowClick={(row) => handleClick(row?.id as string)}
      slots={{
        toolbar: SearchFieldToolbar,
      }}
      slotProps={{
        toolbar: {
          resourceName: "the Brand(s)",
          rowSelection: rowSelectionModel,
          deleteFunction: canDelete ? deleteBrand : undefined,
          setErrorMessage: props.setErrorMessage,
        },
      }}
    />
  );
}
