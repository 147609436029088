/**
 * @file validationSchema.ts Yup validation schema for MSISDNs
 * @author Harry Rhodes
 * @exports Yup.object
 */
import * as Yup from "yup";
import {
  paiMinLength,
  paiMaxLength,
  paiPrefixMinLength,
  paiPrefixMaxLength,
  fromMinLength,
  fromMaxLength,
  fromPrefixMinLength,
  fromPrefixMaxLength
} from "../../../utils/msisdnUtils";

/* eslint-disable no-template-curly-in-string */

export const validationSchema = Yup.object().shape({
  is_pai_prefix: Yup.bool().required(),
  pai: Yup.string()
    .required("PAI is required")
    .matches(/^\+[1-9][0-9]*$/, "PAI must be in format +1234567890")
    .when("is_pai_prefix", {
      is: true,
      then: (schema) =>
        schema
          .min(paiPrefixMinLength, "A PAI prefix must be at least ${min} characters in length")
          .max(paiPrefixMaxLength, "A PAI prefix cannot be longer than ${max} characters"),
      otherwise: (schema) =>
        schema
          .test(
            "is-number-possibly-a-prefix",
            "Is the number a prefix? If yes, please select the prefix box, otherwise the number is too short",
            // if the user entered a value that's OK for a prefix, but not for an PAI, 
            // let this test fail and thus give the user the message asking if they meant to check the box
            (value) => !(value && value.length >= paiPrefixMinLength && value.length < paiMinLength)
          )
          .min(paiMinLength, "PAI must be at least ${min} characters in length")
          .max(paiMaxLength, "PAI cannot be longer than ${max} characters")
    }),
  is_from_prefix: Yup.bool().required(),
  from: Yup.string()
    .matches(/^\+[1-9][0-9]*$/, "From must be in format +1234567890")
    .when("is_from_prefix", {
      is: true,
      then: (schema) =>
        schema
          .min(fromPrefixMinLength, "A From-prefix must be at least ${min} characters in length")
          .max(fromPrefixMaxLength, "A From-prefix cannot be longer than ${max} characters"),
      otherwise: (schema) =>
        schema
          .test(
            "is-number-possibly-a-prefix",
            "Is the number a prefix? If yes, please select the prefix box, otherwise the number is too short",
            // if the user entered a value that's OK for a prefix, but not for an From, 
            // let this test fail and thus give the user the message asking if they meant to check the box
            (value) => !(value && value.length >= fromPrefixMinLength && value.length < fromMinLength)
          )
          .min(fromMinLength, "From must be at least ${min} characters in length")
          .max(fromMaxLength, "From cannot be longer than ${max} characters")
    })
});
