/**
 * @file style.ts Defines CSS styles for MSISDNs components
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  dialog: {
    overflow: "hidden",
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  bulkUploadSummary: {
    marginTop: "1em",
    backgroundColor: "#ABEBC6"
  },
  bulkUploadErrorTable: {
    backgroundColor: "#FFCDD2"
  },
}));

export default useStyles;
