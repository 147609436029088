import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import {
  parseGroupName,
  prettyParseGroupName,
} from "../../../../../../utils/utils";
import { useNavigate, useLocation } from "react-router-dom";
import useStyles from "../../../../templates/style";
/**
 * RoleMapping
 * @typedef {{group: string, role: string}} RoleMapping
 */
interface RoleMapping {
  group: string;
  role: string;
  name: string;
}
/**
 * Props
 * @typedef {{open: boolean, handleClose: function}} Props
 */
interface Props {
  open: boolean;
  handleClose: () => void;
}
/**
 * Renders SwitchRole component
 * @param props component props @see Props
 * @returns {React.Component} SwitchRole component
 */
export default function SwitchRole(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const [availableRoles, setAvailableRoles] = useState<RoleMapping[]>([]);

  useEffect(() => {
    let isMounted = true;
    getRoles();
    
    async function getRoles() {
      let { signInUserSession } = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      let groups = await signInUserSession.idToken.payload["cognito:groups"];
      let parsedRoles: RoleMapping[] = [];
      for (let i = 0; i < groups?.length; i++) {
        let prettyName = await prettyParseGroupName(groups[i]);
        if (prettyName) {
          parsedRoles.push({
            group: groups[i],
            role: parseGroupName(groups[i]),
            name: prettyName,
          });
        }
      }
      if (isMounted) {
        parsedRoles.sort(function (a, b) {
          if (a.role < b.role) {
            return -1;
          }
          if (a.role > b.role) {
            return 1;
          }
          return 0;
        });
        setAvailableRoles(parsedRoles);
      }
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const handleListItemClick = (value: string) => {
    localStorage.setItem("selectedGroup", value);
    if (location.pathname !== "/") {
      navigate("/");
    }
    window.location.reload();
  };

  return (
    <Dialog
      onClose={props.handleClose}
      aria-labelledby="simple-dialog-title"
      open={props.open}
    >
      <DialogTitle id="simple-dialog-title">Switch Role</DialogTitle>
      <div className={classes.centerDiv} style={{ minWidth: "300px" }}>
        <List>
          {availableRoles.map((x) => (
            <ListItem
              button
              onClick={() => handleListItemClick(x.group)}
              key={x.group}
            >
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={x.name} />
            </ListItem>
          ))}
        </List>
      </div>
    </Dialog>
  );
}
