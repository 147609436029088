/**
 * @file PartnerManagerList.tsx
 * @description Defines Navigation list items for Partner Manager role.
 * @author Harry Rhodes
 * @exports React.Fragment
 */
import { useState } from "react";
import { List, Collapse, ListItemButton } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import InfoIcon from "@mui/icons-material/Info";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PeopleIcon from "@mui/icons-material/People";
import useStyles from "../common/styles";
import { getDashboardMenuTitle } from "../../../../../utils/featureToggle";
/**
 * Props
 * @typedef {{partnerId: string}} Props
 */
interface Props {
  partnerId: string;
}
/**
 * Renders PartnerManagerList
 * @param props component props @see Props
 * @returns {React.Component} PartnerManagerList
 */
export default function PartnerManagerList(props: Props) {
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List>
      <ListItemButton component="a" href="/">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={getDashboardMenuTitle()} />
      </ListItemButton>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <BusinessCenterIcon />
        </ListItemIcon>
        <ListItemText primary="Your Account" aria-label="your account button" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            className={classes.nested}
            component="a"
            href={"/partnerInfo"}
          >
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="Partner Info" />
          </ListItemButton>
          <ListItemButton
            className={classes.nested}
            component="a"
            href={"/reports"}
          >
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton component="a" href={"/users"}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItemButton>
    </List>
  );
}
