/**
 * @file OPCOCardTable.tsx OPCOCardTable component
 * @author Harry Rhodes
 * @exports React.Component
 */
import { Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import opcoService, { OPCOType } from "../../../services/opcoService";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import SearchFieldToolbar from "../../common/DataGrid/SearchFieldToolbar";
import useStyles, { sxProps } from "./styles";
import { useState } from "react";
import DataGridLogo from "../../common/DataGrid/DataGridLogo";
import { useMutation, useQueryClient } from "react-query";
import { useUserInfo } from "../../../hooks/useUserInfo";
import { invalidateOpcoQueries } from "../../../utils/invalidateQueries";
/**
 * Props
 * @typedef {{data: OPCOType[]}} Props
 */
interface Props {
  data: OPCOType[];
  setSuccessMessage: (s: string) => void;
  setErrorMessage: (s: string) => void;
}

/**
 * Renders OPCOsCardTable component
 * @param props component props @see Props
 * @returns {React.Component} OPCOsCardTable component
 */
export default function OPCOsCardTable(props: Props) {
  const navigate = useNavigate();
  const { role } = useUserInfo();
  const location = useLocation();
  const classes = useStyles();
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const queryClient = useQueryClient();
  const { mutateAsync: deleteOpco } = useMutation((ids: string[]) => Promise.all(ids.map(opcoService.delete)), {
    onSuccess: (results) => {
      props.setSuccessMessage("Deleted " + results.length + " OPCO(s)!");
    },
    onSettled: () => {
      invalidateOpcoQueries(queryClient);
    }
  });

  /**
   * Navigates to relevent opco based on role/current location
   */
  const handleClick = (id: string) => {
    navigate(location.pathname + "/" + id);
  };

  if (props.data.length === 0) return <Typography>No OPCOs</Typography>;

  const columns: GridColDef[] = [
    {
      field: 'logo',
      headerName: '',
      renderCell: (params) => <DataGridLogo logoSrc={params.value.opcoLogo} />,
      width: 150,
      sortable: false
    },
    { field: 'name', headerName: 'Name', width: 450 },
    { field: 'alias', headerName: 'Alias', width: 250 },
    { field: 'market', headerName: 'Market', width: 200 }
  ];

  const rows = props.data.map((opco) => ({
    id: opco.id,
    logo: { opcoLogo: opco.logo },
    name: opco.name,
    alias: opco.alias,
    market: opco.market
  }));

  const canDelete: boolean = role === "vodafone-admin";

  return (
    <DataGrid className={classes.datagrid}
      // Style for search toolbar
      sx={sxProps.datagridToolbar}
      disableColumnMenu
      disableRowSelectionOnClick
      autoHeight
      checkboxSelection={canDelete}
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' }],
        },
      }}
      pageSizeOptions={[10, 20, 50, 100]}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      onRowClick={(row) => handleClick(row?.id as string)}
      slots={{
        toolbar: SearchFieldToolbar,
      }}
      slotProps={{
        toolbar: {
          resourceName: "the OPCO(s)",
          rowSelection: rowSelectionModel,
          deleteFunction: canDelete ? deleteOpco : undefined,
          setErrorMessage: props.setErrorMessage,
        },
      }}
    />
  );
}
