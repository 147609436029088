/**
 * @file style.ts
 * @description Defines application wide common CSS styles
 * @author Harry Rhodes
 * @exports makeStyles
 */
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    boxShadow: "0px 2.5px 5px 0px #949698",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  tableButtons: {
    marginLeft: theme.spacing(1),
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  //Asset Cards
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 2.5px 5px 0px #949698",
    [theme.breakpoints.between("xs", "md")]: {
      minWidth: "200px",
      maxWidth: "500px",
      minHeight: "100px",
      maxHeight: "600px",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      minWidth: "200px",
      maxWidth: "600px",
      minHeight: "100px",
      maxHeight: "700px",
    },
    [theme.breakpoints.between("md", "xl")]: {
      minWidth: "300px",
      maxWidth: "600px",
      minHeight: "300px",
      maxHeight: "600px",
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "300px",
      maxWidth: "500px",
      minHeight: "250px",
      maxHeight: "400px",
    },
  },
  cardHeader: {
    fontSize: "16px",
  },
  cardMedia: {
    [theme.breakpoints.between("xs", "md")]: {
      minWidth: "200px",
      maxWidth: "500px",
      minHeight: "100px",
      maxHeight: "600px",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      minWidth: "200px",
      maxWidth: "600px",
      minHeight: "100px",
      maxHeight: "600px",
    },
    [theme.breakpoints.between("md", "xl")]: {
      minWidth: "300px",
      maxWidth: "600px",
      minHeight: "300px",
      maxHeight: "600px",
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "300px",
      maxWidth: "500px",
      minHeight: "250px",
      maxHeight: "400px",
    },
  },
  cardContent: {
    flexGrow: 1,
  },
  //Fab
  fab: {
    position: "absolute",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  centerDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  centerDivRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
}));

export default useStyles;
