/**
 * @file validationSchema.ts Yup validation schema for Campaigns
 * @author Harry Rhodes
 * @exports Yup.object
 */
import * as Yup from "yup";
import { AntiSpoofingType } from "../../../services/campaignService";
import { createYupDisplayName, yupTrimmedString } from "../../../utils/validationUtils";

/* eslint-disable no-template-curly-in-string */

export const validationSchema = Yup.object().shape({
  name: yupTrimmedString("Name")
    .min(2, "Name cannot be shorter than ${min} characters")
    .max(100, "Name cannot be longer than ${max} characters")
    .required("Required"),
  desc: yupTrimmedString("Description")
    .max(500, "Description cannot be longer than ${max} characters")
    .required("Required"),
  display_name: createYupDisplayName("Display Name"),
  campaign_anti_spoofing_enabled: Yup.boolean()
    .required(),
  anti_spoofing: Yup.string()
    .when("campaign_anti_spoofing_enabled", {
      is: true,
      then: Yup.string()
        .test(
          "Check if anti-spoofing type was selected",
          "Please select the anti-spoofing type",
          (value: any, ctx: Yup.TestContext) => value !== AntiSpoofingType.DISABLED
        )
        .required()
    })    
});
