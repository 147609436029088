/**
 * @file ChartDesignerForm.tsx component
 * @author Rafael Mello
 * @exports React.Component
 */
import { Grid, Button, FormHelperText, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Formik, Form, FormikValues } from "formik";
import { validationSchema } from "./validationSchema";
import CampaignsTable from "../../Campaigns/CampaignsTable";
import RadioSelect from "../../common/templates/forms/RadioSelect";
import { useQuery } from "react-query";
import brandService, { BrandType } from "../../../services/brandService";
import campaignService, { CampaignType } from "../../../services/campaignService";
import msisdnService, { MSISDNType } from "../../../services/msisdnService";
import OnError from "../../common/templates/feedback/OnError";
import OnLoad from "../../common/templates/feedback/OnLoad";
import useStyles from "../../common/templates/style";
import AnsweredCallsChart from "../../Charts/AnsweredCallsChart";
import { useState } from "react";
import reportService, { ReportData } from "../../../services/reportService";
import { ApiCallParams, ReportLevelOfDetail, toIsoDate } from "../ReportsForm/ReportsForm";
import LineChart from "../../Charts/LineChart";
import DurationBucketChart from "../../Charts/DurationBucketChart";
import AlertSnackback from "../../common/templates/feedback/AlertSnackbar";
import { extractErrorMessage } from "../../../utils/utils";

interface Props {
  brandId: string;
}

/**
 * Chart Designer form component. Shows a UI to select start date, end date,
 * campaign(s) and metric in which the generated chart will be based on.
 */
export default function ReportsForm(props: Props) {
  const classes = useStyles();
  const [chart, setChart] = useState<any>();
  const [msg, setMsg] = useState("");
  const [error, setError] = useState(false);

  const ANSWERED_NOT_ANSWERED = "answeredNotAnswered";
  const PICK_UP_RATE = "pickUpRate";
  const AVERAGE_CALL_DURATION = "averageCallDuration";
  const CALL_BUCKET_ANALYSIS = "callBucketAnalysis";

  const {
    data: brand,
    isLoading: loadingBrand,
    error: brandError,
  } = useQuery<BrandType>(["brand", props.brandId], () => brandService.getSingle(props.brandId));

  const {
    data: campaigns,
    isLoading: loadingCampaigns,
    error: campaignsError,
  } = useQuery<CampaignType[]>(["campaigns", props.brandId], () => campaignService.getByParam({ brandId: props.brandId }));

  const {
    data: msisdns,
    isLoading: loadingMsisdns,
    error: msisdnsError,
  } = useQuery<MSISDNType[]>(["msisdns", props.brandId], () => msisdnService.getAll(props.brandId));

  const isLoading: boolean = loadingBrand || loadingCampaigns || loadingMsisdns;
  const renderError = brandError || campaignsError || msisdnsError;

  if (isLoading) return <OnLoad/>;
  if (renderError) return <OnError error={renderError} />;

  async function getSelectedData(reportType: "aggregate" | "raw", values: FormikValues): Promise<ReportData> {

    const fromDate = new Date(values.from);
    const toDate = new Date(values.to);
    
    const params: ApiCallParams = {
      id: props.brandId,
      from: toIsoDate(fromDate),
      to: toIsoDate(toDate),
      type: reportType,
      levelOfDetail: ReportLevelOfDetail.CAMPAIGNS,
      callAnalytics: true
    };

    const chartData = await reportService.getByBrandId(params).catch((e) => {
      setMsg(extractErrorMessage(e));
      setError(true);
    });

    // Only consider rows containing selected campaigns.
    // Extract campaign name from campaignIdsWithNames.
    // Format of each value: campaign.id + ";" + campaign.name
    const selectedCampaignNames = values.campaignIdsWithNames.map(
      (idWithName: string) => idWithName.substring(idWithName.indexOf(';') + 1)
    );
    const selectedRows = (chartData as ReportData).rows.filter((row) => selectedCampaignNames.includes(row.campaign!));
    const selectedReportData = {...(chartData as ReportData), rows: selectedRows};

    if (reportType === "raw") {
      // Add artificial rows for days that don't have any data.
      // We want to include every day of the selected period in the chart.
      while(fromDate <= toDate) {
        const rowDate = toIsoDate(fromDate);
        if (!selectedReportData!.rows.some(row => row.date === rowDate)) {
          // Adding rows for one of the selected campaigns is enough.
          selectedReportData!.rows.push({count: "0", date: rowDate, campaign: selectedCampaignNames[0]});
        }
        fromDate.setDate(fromDate.getDate() + 1);
      }
      selectedReportData!.rows.sort((a, b) => a.date! < b.date! ? -1 : 1);
    }

    return selectedReportData;
  }

  function generateAnsweredCallsChart(chartData: ReportData, chartName: string) {
    setChart(
      <AnsweredCallsChart chartData={chartData} chartName={chartName} />
    );
  }

  function generatePickUpRateChart(chartData: ReportData, chartName: string) {
    setChart(
      <LineChart
        chartData={chartData}
        chartName={chartName}
        label="Pick-Up Rate"
        metric="pickUpRate"
        yInPercentage={true}
      />
    );
  }

  function generateAverageCallDurationChart(chartData: ReportData, chartName: string) {
    setChart(
      <LineChart
        chartData={chartData}
        chartName={chartName}
        label="Average Call Duration (seconds)"
        metric="averageCallDuration"
      />
    );
  }

  function generateCallBucketAnalysisChart(chartData: ReportData, chartName: string) {
    setChart(
      <DurationBucketChart chartData={chartData} chartName={chartName} />
    );
  }

  return (
    <>
      <Formik
        initialValues={{
          from: "",
          to: "",
          campaignIdsWithNames: [],
          metric: "",
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values: FormikValues) => {
          const chartName = "chart_brand-" + brand!.alias + "_";
          if (values.metric === CALL_BUCKET_ANALYSIS) {
            const chartData = await getSelectedData("aggregate", values);
            generateCallBucketAnalysisChart(chartData, chartName + "segmentationByCallDuration");
          } else {
            const chartData = await getSelectedData("raw", values);
            if (values.metric === ANSWERED_NOT_ANSWERED) {
              generateAnsweredCallsChart(chartData, chartName + "answeredCalls");
            }
            if (values.metric === PICK_UP_RATE) {
              generatePickUpRateChart(chartData, chartName + "pickUpRate");
            }
            if (values.metric === AVERAGE_CALL_DURATION) {
              generateAverageCallDurationChart(chartData, chartName + "averageCallDuration");
            }
          }
        }}
      >
        {({ errors, values, setFieldValue }) => (
          <Form>
            <Typography marginTop="1em" marginBottom="1em" fontWeight="bold">Step 1: select time period</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <DatePicker
                    label="Start date"
                    value={values.from}
                    onChange={(v) => setFieldValue("from", v)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DatePicker
                    label="End date"
                    value={values.to}
                    onChange={(v) => setFieldValue("to", v)}
                  />
                </Grid>
              </Grid>
              {(errors.to || errors.from) && (
                <FormHelperText error={true}>
                  {errors.to || errors.from}
                </FormHelperText>
              )}
            </LocalizationProvider>
            <Grid container spacing={3}>
              <Grid item>
                <Typography marginTop="1em" marginBottom="1em" fontWeight="bold">Step 2: select one or up to 5 Campaigns</Typography>
                <CampaignsTable
                  data={campaigns!}
                  brand={brand!}
                  msisdns={msisdns!}
                  setCampaignSelection={(v) => setFieldValue("campaignIdsWithNames", v)}
                />
                {(errors.campaignIdsWithNames) && (
                  <FormHelperText error={true}>
                    {errors.campaignIdsWithNames}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item>
                <Typography marginTop="1em" marginBottom="1em" fontWeight="bold">Step 3: select metric</Typography>
                <RadioSelect
                    name="metric"
                    options={[
                      {label: "Answered vs Not Answered Calls", value: ANSWERED_NOT_ANSWERED},
                      {label: "Pick-Up Rate", value: PICK_UP_RATE},
                      {label: "Average Call Duration", value: AVERAGE_CALL_DURATION},
                      {label: "Call Bucket Analysis", value: CALL_BUCKET_ANALYSIS},
                    ]}
                    row={true}
                />
                {(errors.metric) && (
                  <FormHelperText error={true}>
                    Please select the metric
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.button}
            >
              Generate Chart
            </Button>
          </Form>
        )}
      </Formik>
      {chart}
      <AlertSnackback
        message={msg}
        type="error"
        open={error}
        setOpen={setError}
      />
    </>
  );
}
