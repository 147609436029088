import { useEffect, useState } from "react";
import defaultLogo from "../../../assets/images/default-logo.png";
import { Storage } from "aws-amplify";
import { CardMedia, Grid } from "@mui/material";
import useStyles from "./styles";

interface Props {
  logoSrc?: string;
}

/**
 * Separate component to handle loading data and then passing it to renderCell.
 * The renderCell typings seems to accept an asynchronous callback but don't actually await the promise and calls the function synchronously.
 */
export default function DataGridLogo(props: Props) {
  const [signedURL, setSignedURL] = useState("");
  const classes = useStyles();

  useEffect(() => {
    const getImg = async () => {
      if (props.logoSrc) {
        const res = await Storage.get(props.logoSrc);
        setSignedURL(res);
      } else {
        setSignedURL(defaultLogo);
      }
    };
    getImg();
  }, []);

  return (
    <Grid item xs={12} className={classes.mediaContainer}>
      <CardMedia
        component="img"
        src={signedURL}
        className={classes.media}
      />
    </Grid>
  );
}