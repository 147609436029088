import { useEffect, useState, Ref } from "react";
import { useQuery } from "react-query";
import { Grid, Typography } from "@mui/material";
import FieldFW from "../../templates/forms/FieldFW";
import { Formik, Form } from "formik";
import { validationSchema } from "./validationSchema";
import { useUserContext } from "../../../../context/UserContext";
import userService, { UserReadAll } from "../../../../services/userService";
import useStyles from "./styles";
import AutoCompleteFW from "../../templates/forms/AutoCompleteFW";

export type UserInfoType = {
  username: string;
};

export interface UserState {
  partnerAlias?: string;
}
export interface UserFormType {
  new_user?: string;
  existing_user?: string | null;
}
interface Props {
  setUser: (user: UserInfoType) => void;
  userState?: UserState;
  handleNext?: () => void;
  innerRef?: Ref<any>;
}

export default function UserInfo(props: Props) {
  const classes = useStyles();
  const { setUser, userState } = props;
  const { user } = useUserContext();
  const { role } = user;
  const [groupName, setGroupName] = useState("");
  //TODO: move to add user logic
  // useEffect(() => {
  //   switch (user.role) {
  //     case "vodafone-admin":
  //       if (userState && userState.partnerAlias) {
  //         setGroupName(userState.partnerAlias + "-partner-manager");
  //         setRoles(["Partner Manager", "Brand Manager", "Brand Reporter"]);
  //       } else if (userState && userState.opcoAlias) {
  //         setGroupName(userState.opcoAlias + "-opco-admin");
  //         setRoles(["OPCO Admin"]);
  //       } else {
  //         setGroupName("vodafone-admin");
  //         setRoles([
  //           "Aggregator Admin",
  //           "OPCO Admin",
  //           "Partner Manager",
  //           "Brand Manager",
  //           "Brand Reporter",
  //         ]);
  //       }

  //       break;

  //     case "partner-manager":
  //       setGroupName(user.partnerAlias + "-partner-manager");
  //       setRoles(["Partner Manager", "Brand Manager", "Brand Reporter"]);
  //       break;
  //   }
  // }, [userState, role]);
  useEffect(() => {
    switch (user.role) {
      case "vodafone-admin":
        if (userState && userState.partnerAlias) {
          setGroupName(userState.partnerAlias + "-partner-manager");
        } else {
          setGroupName("vodafone-admin");
        }

        break;
      case "partner-manager":
        setGroupName(user.partnerAlias + "-partner-manager");
        break;
    }
  }, [userState, role]);

  const getUsersList = async (groupName: string): Promise<UserReadAll[]> => {
    let usersList: UserReadAll[] = await userService.getAll(groupName);
    if (user.role === "vodafone-admin" && userState && userState.partnerAlias) {
      // if an admin is adding a Brand, add current user to the list of existing users
      // @see https://rncorp.atlassian.net/browse/ERBT-6467
      
      // only add current user to the list if it is not already there
      if (!usersList.find(element => element.Username === user.username)) {
        const currentUser: UserReadAll = {
          // only username and email are important
          Username: user.username? user.username : "",
          Email: user.email,
          Attributes: [],
          UserCreateDate: "",
          UserLastModifiedDate: "",
          Enabled: false,
          UserStatus: "",
          EmailVerified: false,
          user: {
            Username: user.username? user.username : "",
            Attributes: [],
            UserCreateDate: "",
            UserLastModifiedDate: "",
            Enabled: false,
            UserStatus: ""
          }
        }
        usersList.push(currentUser);
      }
    }
    return usersList;
  }

  const {
    data: users,
    isLoading: loadingUsers,
    error: usersError,
  } = useQuery<UserReadAll[]>(
    ["users", groupName],
    () => getUsersList(groupName),
    {
      enabled: !!groupName && groupName !== "",
    }
  );

  return (
    <Formik
      innerRef={props.innerRef}
      validateOnChange={false}
      initialValues={{
        new_user: "",
        existing_user: null,
      }}
      validationSchema={validationSchema}
      onSubmit={(data: UserFormType, { setSubmitting }) => {
        setSubmitting(true);
        if (data.existing_user) {
          let user: UserInfoType = {
            username: data.existing_user,
          };
          setUser(user);
        } else if (data.new_user) {
          let user: UserInfoType = {
            username: data.new_user,
          };
          setUser(user);
        }
        setSubmitting(false);
        if (props.handleNext) {
          props.handleNext();
        }
      }}
    >
      <Form>
        <div className={classes.container}>
          <Grid container spacing={2}>
            <Grid item container spacing={1} xs={12} md={6}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.title}>
                  Existing User
                </Typography>
                <AutoCompleteFW
                  required
                  id="existing_user"
                  name="existing_user"
                  loading={loadingUsers}
                  placeholder="Existing User"
                  values={users?.map((u) => u.Email || u.Username)}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.title}>
                    New User
                  </Typography>
                  <FieldFW
                    type="text"
                    required
                    id="new_user"
                    name="new_user"
                    placeholder="Email"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Form>
    </Formik>
  );
}
