/**
 * @file BrandReporterList.tsx
 * @description Defines Navigation list items for Brand Reporter role.
 * @author Harry Rhodes
 * @exports React.Fragment
 */
import { useState } from "react";
import { List, Collapse, ListItemButton } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ImageIcon from "@mui/icons-material/Image";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import InfoIcon from "@mui/icons-material/Info";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AssessmentIcon from "@mui/icons-material/Assessment";
import useStyles from "../common/styles";
import { getDashboardMenuTitle, isAssetsFeatureEnabled } from "../../../../../utils/featureToggle";
import { Addchart } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
/**
 * Props
 * @typedef {{partnerId: string, brandId: string}} Props
 */
interface Props {
  partnerId: string;
  brandId: string;
}
/**
 * Renders BrandReporterList
 * @param props component props @see Props
 * @returns {React.Component} BrandReporterList
 */
export default function BrandReporterList(props: Props) {
  const [open, setOpen] = useState(true);
  const [openReports, setOpenReports] = useState(true);
  const classes = useStyles();
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/reports");
  };
  const handleClick = () => {
    setOpen(!open);
  };
  const handleReportsClick = () => {
    setOpenReports(!openReports);
  };

  return (
    <List>
      <ListItemButton component="a" href="/">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={getDashboardMenuTitle()} />
      </ListItemButton>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <BusinessCenterIcon />
        </ListItemIcon>
        <ListItemText primary="Your Brand" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            className={classes.nested}
            component="a"
            href={"/brandInfo"}
          >
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="Brand Info" />
          </ListItemButton>

          {isAssetsFeatureEnabled() &&
            <ListItemButton
              className={classes.nested}
              component="a"
              href={"/assets"}
            >
              <ListItemIcon>
                <ImageIcon />
              </ListItemIcon>
              <ListItemText primary="Assets" />
            </ListItemButton>
          }

          <ListItemButton className={classes.nested}>
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" onClick={handleNavigate} />
            {openReports ? <ExpandLess onClick={handleReportsClick} /> : <ExpandMore onClick={handleReportsClick} />}
          </ListItemButton>
          <Collapse in={openReports} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                className={classes.doubleNested}
                component="a"
                onClick={() => navigate("/chartDesigner", {
                  state: { brandId: props.brandId },
                })}
              >
                <ListItemIcon>
                  <Addchart />
                </ListItemIcon>
                <ListItemText primary="Chart Designer" />
              </ListItemButton>
            </List>
          </Collapse>

        </List>
      </Collapse>
    </List>
  );
}
