import { ReactNode, useEffect, useState } from "react";
import { Paper, Box, Grid, Typography, Stepper, Link } from "@mui/material";
import Copyright from "../components/common/Copyright";
import SignInForm from "../components/SignIn/SignInForm/SignInForm";
import MFAForm from "../components/SignIn/MFAForm";
import NewPasswordForm from "../components/SignIn/NewPasswordForm";
import CodeConfirmation from "../components/SignIn/CodeConfirmation";
import ForgotPasswordForm from "../components/SignIn/ForgotPasswordForm";
import Logo from "../components/common/Logo";
import SetupMFAForm from "../components/SignIn/SetupMFAForm";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import useStyles from "../components/SignIn/styles";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useLocation } from "react-router-dom";
import AlertSnackback from "../components/common/templates/feedback/AlertSnackbar";
import { ReactComponent as SvgConnectivitySystem } from "../components/SignIn/Assets/connectivity-system.svg";
import { ReactComponent as SvgSettingsSystem } from "../components/SignIn/Assets/settings-system.svg";
import { ReactComponent as SvgSecurity } from "../components/SignIn/Assets/security-assessment-mid.svg";
import { ReactComponent as SvgSMissed } from "../components/SignIn/Assets/verpasste-chancen.svg";
import { ReactComponent as SvgCarerSystem } from "../components/SignIn/Assets/carer-system.svg";
import smartphoneImg from "../components/SignIn/Assets/smartphone-mock-up.png";
import SvgContent from "../components/SignIn/SvgContent/SvgContent";

export default function SignIn() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [previousSteps] = useState<number[]>([]);
  const [content, setContent] = useState<ReactNode>(<div></div>);
  const [user, setUser] = useState<CognitoUser>();
  const [username, setUsername] = useState<string>("");
  const [verify, setVerify] = useState(true);
  const [msg, setMsg] = useState("");
  const [success, setSuccess] = useState(false);

  let location = useLocation();
  
  const handleNext = (step: number) => {
    previousSteps.push(activeStep);
    setActiveStep(step);
  };

  const handleBack = () => {
    let previousStep = previousSteps.pop();
    setActiveStep(previousStep ? previousStep : 0);
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        let state = location.state as { msg: string };
        if (state?.msg) {
          setMsg(state.msg);
          setSuccess(true);
          // clear location and state so message doesn't show again
          window.history.replaceState(null, '');
          location.state = null;
        }
        return (
          <SignInForm
            setActiveStep={handleNext}
            setUser={setUser}
            setVerify={setVerify}
            setUsername={setUsername}
          />
        );
      case 1:
        return <MFAForm user={user} />;
      case 2:
        return (
          <ForgotPasswordForm
            setActiveStep={handleNext}
            setUsername={setUsername}
          />
        );
      case 3:
        return (
          <NewPasswordForm
            setActiveStep={handleNext}
            user={user}
          />
        );
      case 4:
        return <SetupMFAForm user={user} />;
      case 5:
        return (
          <CodeConfirmation
            username={username}
            verify={verify}
          />
        );
      default:
        return <div></div>;
    }
  }

  useEffect(() => setContent(getStepContent(activeStep)), [activeStep]);

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <div className={classes.graySquare}>
          <Typography variant="h4" color="white" fontWeight="bold">
            Vodafone CallerID
          </Typography>
          <div>
            <Typography variant="h6" color="white" className={classes.introText}>
              Laut einer Studie haben 3 von 4 Personen wichtige Telefonate
              verpasst, weil sie nicht wussten, wer anruft.
            </Typography>
            <Typography variant="h6" color="white" className={classes.introText}>
              Mit Vodafone CallerID können Festnetznummern mit Informationen
              angereichert werden, die es ermöglichen, mehr Transparenz und
              Vertrauen bei den Anrufempfängern zu schaffen und damit die
              Annahmequote bei Anrufversuchen zu erhöhen.
            </Typography>
            <img className={classes.smartphoneImg} src={smartphoneImg} alt="Smartphone-Img" />
          </div>
          <div className={classes.whiteSquare}>
            <Typography variant="h4" color="#34342b">
              <Box fontWeight="bold">Ihre Vorteile durch Vodafone CallerID</Box>
              <SvgContent 
                svg={SvgConnectivitySystem}
                title={"Netzbasierte Lösung"}
                text={"Vodafone CallerID ist eine netzbasierte Lösung, die keinen technischen Implementierungsaufwand auf Seiten des Anrufers erfordert."}
              />
              <SvgContent 
                svg={SvgSettingsSystem}
                title={"Erhöhte Effizienz"}
                text={"Unternehmen können somit die Anzahl der hochwertigen Verkaufsgespräche deutlich erhöhen und damit mehr Umsatz generieren."}
              />
              <SvgContent 
                svg={SvgSecurity}
                title={"Mehr Sicherheit"}
                text={"Verbraucher können die Relevanz der Anrufe besser beurteilen."}
              />
              <SvgContent 
                svg={SvgSMissed}
                title={"Verpasste Chancen verhindern"}
                text={"Kunden erkennen auch bei verpassten Anrufen das anrufende Unternehmen, sodass die Annahmequote signifikant gesteigert werden kann."}
              />
              <SvgContent 
                svg={SvgCarerSystem}
                title={"Bessere Kundenbindung"}
                text={"Unternehmen können durch die Verwendung von CallerID sicherstellen, dass ihre Kunden ihre Anrufe erkennen und damit eher annehmen."}
              />
            </Typography>
          </div>
          <Typography variant="h6" color="white" className={classes.textBlock} fontWeight="bold">
            Sie haben Interesse?
          </Typography>
          <Typography variant="h6" color="white" className={classes.textBlock}>
            Wenn Sie die Vorteile nutzen möchten, die Vodafone CallerID Ihnen, Ihrem Unternehmen
            und auch Ihren Kunden bietet, zögern Sie nicht, uns 
            unter <Link href="mailTo:DL-deCallerIDcontact@vodafone.com">DL-deCallerIDcontact@vodafone.com</Link> zu kontaktieren.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <Logo />
            <Typography component="h1" variant="h6" color="secondary" noWrap>
              CallerID Business Portal
            </Typography>
          </div>
          <Paper className={classes.paper}>
            <div>
              {activeStep !== 0 && (
                <KeyboardBackspaceIcon onClick={() => handleBack()} />
              )}
              <Stepper
                nonLinear
                activeStep={activeStep}
                className={classes.stepper}
              >
                {/* {steps.map((label, index) => (
                <Step key={label}></Step>
              ))} */}
              </Stepper>
            </div>

            {content}
          </Paper>
          <div className={classes.legalbanner}>
            <Typography component="h1" variant="body2" color="secondary">
              This system is for the use of authorised users only in accordance
              with Vodafone security policies and procedures. Individuals using
              this device without authorisation or in excess of their authority
              are subject to sanctioning procedures by Vodafone authorities
              and/or law enforcement officials. Vodafone will not be responsible
              for any misuse or personal use of any kind, in its information
              systems, and reserves the right for monitoring systems usage to
              control abusive situations or security policy violations.{" "}
            </Typography>
          </div>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Grid>
      <AlertSnackback
        message={msg}
        type="success"
        open={success}
        setOpen={setSuccess}
      />
    </Grid>
  );
}
