/**
 * @file PartnersTable.tsx PartnersTable component
 * @author Harry Rhodes
 * @exports React.Component
 */
import { Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import partnerService, { PartnerType } from "../../../services/partnerService";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import SearchFieldToolbar from "../../common/DataGrid/SearchFieldToolbar";
import useStyles, { sxProps } from "./styles";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { invalidatePartnerQueries } from "../../../utils/invalidateQueries";
import { useUserInfo } from "../../../hooks/useUserInfo";
import DataGridLogo from "../../common/DataGrid/DataGridLogo";

/**
 * Props
 * @typedef {{data: PartnerType[]}} Props
 */
interface Props {
  data: PartnerType[];
  setSuccessMessage: (s: string) => void;
  setErrorMessage: (s: string) => void;
  shouldNotAllowDelete?: boolean;
}

/**
 * Renders PartnersTable component
 * @param props component props @see Props
 * @returns {React.Component} PartnersTable component
 */
export default function PartnersTable(props: Props) {
  const navigate = useNavigate();
  const { role } = useUserInfo();
  const location = useLocation();
  const classes = useStyles();
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const queryClient = useQueryClient();
  const { mutateAsync: deleteBrand } = useMutation((ids: string[]) => Promise.all(ids.map(partnerService.delete)), {
    onSuccess: (results) => {
      props.setSuccessMessage("Deleted " + results.length + " Partner(s)!");
    },
    onSettled: () => {
      invalidatePartnerQueries(queryClient);
    }
  });

  /**
   * Navigates to relevant partner
   */
  const handleClick = (id: string) => {
    if (location.pathname.includes("partners")) {
      navigate(location.pathname + "/" + id);
    } else {
      navigate(location.pathname + "/partners/" + id);
    }
  };

  if (props.data.length === 0) return <Typography>No Partners</Typography>;

  const columns: GridColDef[] = [
    {
      field: 'logo',
      headerName: '',
      renderCell: (params) => <DataGridLogo logoSrc={params.value.partnerLogo} />,
      width: 150,
      sortable: false
    },
    { field: 'name', headerName: 'Name', width: 450 },
    { field: 'alias', headerName: 'Alias', width: 250 },
    { field: 'paiContingent', headerName: 'PAI Contingent', width: 120, align: 'right' }
  ];

  const rows = props.data.map((partner) => ({
    id: partner.id,
    logo: { partnerLogo: partner.logo },
    name: partner.name,
    alias: partner.alias,
    paiContingent: partner.pai_contingent
  }));

  const canDelete: boolean = !props.shouldNotAllowDelete && role === "vodafone-admin";

  return (
    <DataGrid className={classes.datagrid}
      // Style for search toolbar
      sx={sxProps.datagridToolbar}
      disableColumnMenu
      disableRowSelectionOnClick
      autoHeight
      checkboxSelection={canDelete}
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' }],
        },
      }}
      pageSizeOptions={[10, 20, 50, 100]}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      onRowClick={(row) => handleClick(row?.id as string)}
      slots={{
        toolbar: SearchFieldToolbar,
      }}
      slotProps={{
        toolbar: {
          resourceName: "the Partner(s)",
          rowSelection: rowSelectionModel,
          deleteFunction: canDelete ? deleteBrand : undefined,
          setErrorMessage: props.setErrorMessage,
        },
      }}
    />
  );
}
