/**
 * @file AlertDialog.tsx
 * @description AlertDialog component
 * @author Harry Rhodes
 * @exports React.Component
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface Props {
  resourceName: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  handleYes: () => void;
}
export default function AlertDialog(props: Props) {
  const { resourceName, open, setOpen, handleYes } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`Are you sure you want to delete ${resourceName}?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          If you delete {resourceName} it cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleYes}>
          Yes Delete
        </Button>
        <Button color="secondary" onClick={handleClose} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
