/**
 * @file BulkUploadProgress.tsx
 * @description BulkUploadProgress component to show the results of a Bulk Upload operation
 * @author Rafael Mello
 */
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import useStyles from "../common/style";

export type BulkUploadSummary = {
  newNumbers: number,
  updatedNumbers: number,
  unchangedNumbers: number,
  newCampaigns: number;
}

export type BulkUploadError = {
  pai: string,
  from: string,
  campaignDisplayName: string,
  brandAlias: string,
  errorMsg: string
}

interface Props {
  summary: BulkUploadSummary,
  errors: BulkUploadError[]
}

export default function BulkUploadProgress(props: Props) {
  const classes = useStyles();
  return (
    <>
      <Table className={classes.bulkUploadSummary}>
        <TableBody>
          <TableRow
            key="newNumbers"
          >
            <TableCell>New Numbers</TableCell>
            <TableCell>{props.summary.newNumbers}</TableCell>
          </TableRow>
          <TableRow
            key="updatedNumbers"
          >
            <TableCell>Updated Numbers</TableCell>
            <TableCell>{props.summary.updatedNumbers}</TableCell>
          </TableRow>
          <TableRow
            key="unchangedNumbers"
          >
            <TableCell>Unchanged Numbers</TableCell>
            <TableCell>{props.summary.unchangedNumbers}</TableCell>
          </TableRow>
          <TableRow
            key="newCampaigns"
          >
            <TableCell>New Campaigns</TableCell>
            <TableCell>{props.summary.newCampaigns}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      { props.errors.length > 0 ?
        (
          <Table className={classes.bulkUploadErrorTable}>
            <TableHead>
              <TableRow>
                <TableCell>PAI</TableCell>
                <TableCell>From</TableCell>
                <TableCell>Campaign Name (Display Name)</TableCell>
                <TableCell>Brand Alias</TableCell>
                <TableCell>Error</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.errors.map((error) => (
                <TableRow
                  key={error?.pai + error?.from}
                >
                  <TableCell>{error.pai}</TableCell>
                  <TableCell>{error.from}</TableCell>
                  <TableCell>{error.campaignDisplayName}</TableCell>
                  <TableCell>{error.brandAlias}</TableCell>
                  <TableCell>{error.errorMsg}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )
        : <div></div>
      }
    </>
  );
}
