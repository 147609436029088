/**
 * @file SearchFieldToolbar Defines a GridToolbar to be used in a DataGrid component.
 * The toolbar only contains a DeleteIcon to delete the selected rows and a Search text field for filtering.
 * This is also useful to omit unnecessary/undesired Panel buttons from DataGrid default GridToolbar.
 * @author Rafael Mello
 */

import { FormControlLabel, IconButton, Switch } from "@mui/material";
import { GridRowSelectionModel, GridToolbarContainer, GridToolbarProps, GridToolbarQuickFilter, ToolbarPropsOverrides } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';
import { extractErrorMessage } from "../../../utils/utils";
import AlertDialog from "../templates/feedback/AlertDialog";
import { useState } from "react";
import useStyles from "./styles";

interface Props {
  // The resourceName string shown in the Alert dialog.
  // "Are you sure you want to delete ${resourceName}?"
  resourceName: string;
  // The selected rows.
  rowSelection: GridRowSelectionModel
  // Called when using the trash bin. 
  // Trash bin will not be shown if this is not defined.
  deleteFunction?: (rowSelection: GridRowSelectionModel) => Promise<void>;
  // Called when deleteFunction fails.
  setErrorMessage: (errorMessage: string) => void;
  // Whether the Sub-roles toggle should be checked or not. 
  showSubRoles: boolean;
  // Changes the value of the Sub-roles toggle.
  setShowSubRoles?: (s: boolean) => void;
}

export default function SearchFieldToolbar(p: GridToolbarProps  & ToolbarPropsOverrides) {

  const classes = useStyles();
  const [openConfirm, setOpenConfirm] = useState(false);

  const props = p as Readonly<Props>

  const handleDelete = () => {
    setOpenConfirm(false);
    props.deleteFunction!(props.rowSelection).catch((err: any) => {
      props.setErrorMessage(extractErrorMessage(err));
    })
  };

  return (
    <div>
      <GridToolbarContainer>
        {props.deleteFunction && (
          <IconButton onClick={() => setOpenConfirm(true)} >
            <DeleteIcon />
          </IconButton>
        )}
        <div className={classes.filters}>
          {props.setShowSubRoles && (
            <FormControlLabel
              control={ <Switch checked={props.showSubRoles} onChange={(e) => props.setShowSubRoles!(e.target.checked)} /> }
              label="Show roles for sub-entities" 
            />
          )}
          <GridToolbarQuickFilter />
        </div>
      </GridToolbarContainer>
      <AlertDialog
        resourceName={props.resourceName}
        open={openConfirm}
        setOpen={setOpenConfirm}
        handleYes={handleDelete}
      />
    </div>
  );
}